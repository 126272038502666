import React from 'react';
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import MainBanner from '../components/SolutionCompanyBranding/Sb1mainBanner';

import AboutUs from '../components/SolutionCompanyBranding/Sb2AboutUs';
import Services from '../components/SolutionCompanyBranding/Sb3services';
import HowItWork from '../components/SolutionCompanyBranding/Sb4howItWork';
import Partner from '../components/SolutionCompanyBranding/Partner';
import ContactForm from '../components/Contact/ContactForm';


const Home = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />

            <MainBanner />
            <AboutUs />

            <Services />
            <HowItWork />
            <Partner />
            <ContactForm />

            <Footer />
        </Layout>
    );
}

export default Home ;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;